import { MdLocationPin } from 'react-icons/md'

import { UserProviderUserType } from 'src/providers/UserProvider'

import {
  PaymentSettings,
  PlanterToolkit,
  Spade,
  SupporterToolkit,
  TaxCertificates,
  Transactions,
  TreeRegistries,
  SupportedProjects
} from 'src/components/atoms/Icons'

import {
  adminListApplicationsPath,
  adminListFundingPath,
  adminListRegistriesPath,
  adminListAccountsPath,
  adminTreePoolPath,
  listRegistriesPath,
  adminReportsPath,
  dashboardPath,
  supporterToolkitPath,
  taxCertificatesPath,
  adminBulkPath,
  listProjectsPath,
  adminListProjectsPath,
  giftingPath,
  listPlantingProjectsPath,
  blogPath,
  howItWorksPath,
  leaderboardsPath,
  resourcesPath,
  aboutUsPath,
  plantingEventsPath,
  plantersTakeActionPath,
  businessTakeActionPath,
  individualsFamiliesTakeActionPath,
  listFundingPath,
  listMyRegistriesPath,
  planterToolkitPath,
  viewAccountBillingDetailsPath,
  supportedProjectsPath,
  landscapeScalePath
} from 'src/config/paths'

import { LinkOption, LinkOptionType } from './types'

export const buildUserLinks = ({
  user
}: {
  user?: UserProviderUserType
}): LinkOption[] => {
  const isPlanter = !!user?.Account?.PlanterId
  const isFunder = !!user?.Account?.FunderId

  const dashboardOptions: LinkOption = {
    requiresUser: true,
    path: dashboardPath(),
    label: 'Dashboard'
  }

  const donationOptions: LinkOption = {
    requiresUser: true,
    label: 'Donations',
    options: [
      {
        type: LinkOptionType.Link,
        label: 'Transactions',
        path: listFundingPath,
        description:
          'View your donations, invoices, receipts and gifted e-cards.',
        icon: Transactions
      },
      {
        type: LinkOptionType.Link,
        label: 'Tax certificates',
        path: taxCertificatesPath,
        description: 'Access your tax-deductible donation certificates.',
        icon: TaxCertificates
      },
      {
        type: LinkOptionType.Link,
        label: 'Payment settings',
        path: viewAccountBillingDetailsPath,
        description:
          'Manage payment details, monthly payment details, saved messages and preferred donation regions.',
        icon: PaymentSettings
      },
      {
        type: LinkOptionType.Link,
        label: 'Tree registries',
        path: listMyRegistriesPath,
        description: 'Manage your tree registries for native trees.',
        icon: TreeRegistries
      }
    ]
  }

  const projectOptions: LinkOption = {
    requiresUser: true,
    label: 'Projects',
    options: []
  }

  if (isPlanter && user.Account?.Planter?.ProjectsCount) {
    projectOptions.options?.push({
      type: LinkOptionType.Link,
      label: 'My planting projects',
      path: listProjectsPath,
      icon: Spade,
      description: 'Manage, view and monitor your planting projects.'
    })
  }

  if (isFunder) {
    projectOptions.options?.push({
      type: LinkOptionType.Link,
      label: 'Supported projects',
      path: supportedProjectsPath,
      icon: SupportedProjects,
      description: 'See the projects your donations have supported.'
    })
  }

  projectOptions.options?.push({
    type: LinkOptionType.Link,
    label: 'All planting projects',
    path: listPlantingProjectsPath(),
    icon: MdLocationPin,
    description:
      'Browse all planting projects across New Zealand supported by Trees That Count.'
  })

  const toolAndResourceOptions: LinkOption = {
    requiresUser: true,
    label: 'Resources',
    options: []
    // subMenu: {
    //   title: 'Explore',
    //   subMenuOptions: [
    //     {
    //       type: LinkOptionType.Link,
    //       label: 'News and stories',
    //       path: blogPath()
    //     },
    //     {
    //       type: LinkOptionType.Link,
    //       label: 'Leaderboards',
    //       path: leaderboardsPath()
    //     },
    //     {
    //       type: LinkOptionType.Link,
    //       label: 'For businesses',
    //       path: businessTakeActionPath
    //     },
    //     {
    //       type: LinkOptionType.Link,
    //       label: 'For planters',
    //       path: plantersTakeActionPath
    //     },
    //     {
    //       type: LinkOptionType.Link,
    //       label: 'About us',
    //       path: aboutUsPath
    //     }
    //   ]
    // }
  }

  if (isPlanter) {
    toolAndResourceOptions.options?.push({
      type: LinkOptionType.Link,
      label: 'Planter toolkit',
      path: planterToolkitPath,
      icon: PlanterToolkit,
      description:
        'Access resources to help with your planting project and share your planting stories.'
    })
  }

  if (isFunder) {
    toolAndResourceOptions.options?.push({
      type: LinkOptionType.Link,
      label: 'Supporter toolkit',
      path: supporterToolkitPath,
      icon: SupporterToolkit,
      description: 'Access resources to share your impact.'
    })
  }
  const exploreOptions: LinkOption = {
    label: 'Explore',
    requiresUser: false,
    options: [
      {
        type: LinkOptionType.Link,
        label: 'Planting projects',
        path: listPlantingProjectsPath()
      },
      {
        type: LinkOptionType.Link,
        label: 'News and stories',
        path: blogPath()
      },
      {
        type: LinkOptionType.Link,
        label: 'How it works',
        path: howItWorksPath
      },
      {
        type: LinkOptionType.Link,
        label: 'Leaderboards',
        path: leaderboardsPath()
      },
      {
        type: LinkOptionType.Link,
        label: 'Registries',
        path: listRegistriesPath
      },
      {
        type: LinkOptionType.Link,
        label: 'Resources',
        path: resourcesPath
      },
      {
        type: LinkOptionType.Link,
        label: 'Events',
        path: plantingEventsPath
      },
      {
        type: LinkOptionType.Link,
        label: 'About us',
        path: aboutUsPath
      }
    ]
  }

  const takeActionOptions: LinkOption = {
    label: 'Take action',
    requiresUser: false,
    options: [
      {
        type: LinkOptionType.Link,
        label: 'Gifting',
        path: giftingPath
      },
      {
        type: LinkOptionType.Seperator
      },
      {
        type: LinkOptionType.Link,
        label: 'Individuals and families',
        path: individualsFamiliesTakeActionPath
      },
      {
        type: LinkOptionType.Link,
        label: 'Businesses',
        path: businessTakeActionPath
      },
      {
        type: LinkOptionType.Link,
        label: 'Landscape scale restoration',
        path: landscapeScalePath
      },
      {
        type: LinkOptionType.Link,
        label: 'Planters',
        path: plantersTakeActionPath
      }
    ]
  }

  const guestOptions = [takeActionOptions, exploreOptions]

  const userOptions = [dashboardOptions, projectOptions, toolAndResourceOptions]

  if (isFunder) {
    userOptions.splice(1, 0, donationOptions)
  }

  return user ? userOptions : guestOptions
}

export const buildAdvisorLinks = (): LinkOption[] => [
  {
    label: 'Projects',
    path: adminListProjectsPath,
    requiresUser: true
  },
  {
    label: 'Applications',
    path: adminListApplicationsPath(),
    requiresUser: true
  }
]

export const buildAdminLinks = (): LinkOption[] => [
  {
    label: 'Dashboard',
    path: adminTreePoolPath,
    requiresUser: true,
    options: [
      {
        type: LinkOptionType.Link,
        label: 'Tree pool',
        path: adminTreePoolPath
      },
      {
        type: LinkOptionType.Link,
        label: 'Reports',
        path: adminReportsPath
      },
      {
        type: LinkOptionType.Link,
        label: 'Bulk Gifts',
        path: adminBulkPath
      }
    ]
  },
  { label: 'Funding', path: adminListFundingPath, requiresUser: true },
  {
    label: 'Projects',
    path: adminListProjectsPath,
    requiresUser: true
  },
  {
    label: 'Applications',
    path: adminListApplicationsPath(),
    requiresUser: true
  },
  {
    label: 'People',
    path: adminListAccountsPath,
    requiresUser: true,
    options: [
      {
        type: LinkOptionType.Link,
        label: 'Accounts',
        path: adminListAccountsPath
      },
      {
        type: LinkOptionType.Link,
        label: 'Registries',
        path: adminListRegistriesPath
      }
    ]
  }
]
