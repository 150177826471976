import { useMemo } from 'react'

import { RadioGroupProps, RadioProps } from '../RadioGroup'

export const TreeQuantityRadio = ({
  checked,
  id,
  label,
  onBlur,
  onChange,
  value
}: RadioProps) => {
  const amount = (label as string).split(' ')[0]
  const splitLabel = (label as string).split(' ')[1]
  const isOtherAmount = value === 'other'

  const labelClasses = useMemo(() => {
    let className =
      'flex flex-col justify-center items-center h-[72px] p-4 text-tomThumb hover:bg-olivineDark hover:text-white rounded-[10px] border border-[#DFE7D6]  cursor-pointer transition-all duration-300 ease-in-out'

    if (isOtherAmount) {
      className += ' col-span-full sm:col-span-2'
    } else {
      className += ' col-span-1'
    }

    if (checked) {
      className += ' bg-mostlyGreen text-white bg-aquaSpring'
    } else {
      className += ' bg-aquaSpring border-aquaSpring'
    }

    return className
  }, [checked, isOtherAmount])

  return (
    <label className={labelClasses}>
      <input
        className='sr-only'
        name={id}
        onBlur={onBlur}
        onChange={onChange}
        type='radio'
        value={value}
        checked={checked}
      />
      <span className='select-none text-xl leading-7 font-bold'>{amount}</span>
      <span className='select-none text-[14px] sm:text-[16px] leading-5'>
        {splitLabel}
      </span>
    </label>
  )
}

export const TreeQuantityRadioGroup = ({
  children,
  id,
  size
}: RadioGroupProps & {
  size: number
}) => {
  const gridClasses = useMemo(() => {
    switch (size) {
      case 1:
        return 'grid-cols-1'
      case 2:
        return 'grid-cols-1 sm:grid-cols-3'
      case 3:
        return 'grid-cols-2 sm:grid-cols-4'
      case 4:
        return 'grid-cols-3 sm:grid-cols-5'
      case 5:
        return 'grid-cols-4 sm:grid-cols-6'
      case 6:
        return 'grid-cols-5 sm:grid-cols-7'
      case 7:
        return 'grid-cols-6 sm:grid-cols-8'
      default:
        return 'grid-cols-auto'
    }
  }, [size])

  return (
    <div
      className={`grid grid-rows-2 sm:grid-rows-1 gap-1 md:gap-2 ${gridClasses}`}
      role='radiogroup'
      aria-labelledby={id}
    >
      {children}
    </div>
  )
}
