import { useRouter } from 'next/router'
import { useMemo } from 'react'

import { useUser } from 'src/providers/UserProvider'

import { Button } from 'src/components/atoms/Button'
import { Heading3, Paragraph } from 'src/components/atoms/Typography'
import { HSpaceBetweenStack } from 'src/components/templates/HStack'

import { homePath } from 'src/config/paths'

export interface CancelModalProps {
  closeModal: () => void
  destination?: string
  prompt?: string
  onCancel?: () => void
}

export const CancelModal = ({
  closeModal,
  destination,
  prompt,
  onCancel
}: CancelModalProps) => {
  const router = useRouter()

  const { user, isLoading } = useUser()

  const handleCancel = () => {
    if (onCancel) onCancel()
    closeModal()
    router.push(destination || homePath)
  }

  const cancelPrompt = useMemo(() => {
    if (prompt) return prompt
    if (user && !isLoading) {
      return "You'll be taken back to your dashboard."
    }

    return "You'll be taken back to the homepage."
  }, [user, isLoading, prompt])

  return (
    <div className='w-full sm:w-[520px]'>
      <Heading3>Are you sure you want to cancel?</Heading3>

      <Paragraph>{cancelPrompt}</Paragraph>
      <HSpaceBetweenStack>
        <Button
          tabIndex={0}
          className='mr-2 w-40'
          onClick={closeModal}
          variant='outlined'
        >
          No
        </Button>
        <Button
          tabIndex={0}
          className='mr-2 w-40'
          onClick={handleCancel}
          type='submit'
        >
          Yes
        </Button>
      </HSpaceBetweenStack>
    </div>
  )
}
