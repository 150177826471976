import { useMemo } from 'react'

export type TabSwitchOption<T = string | number> = {
  label: string | JSX.Element
  value: T
}

export type TabSwitchProps = {
  id: string
  options?: Array<TabSwitchOption>
  onChange: (e: React.ChangeEvent<any>) => void
  className?: string
  disabled?: boolean
  value?: string
  color?: 'primary' | 'dark' | 'green'
  size?: 'large' | 'small'
}

const TabSwitch = ({
  id,
  options,
  onChange,
  disabled,
  className = '',
  color = 'primary',
  size = 'large',
  value
}: TabSwitchProps) => {
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e)
    }
  }

  const containerColorClasses = useMemo(() => {
    let colorStyles = ''
    switch (color) {
      case 'dark':
        colorStyles = 'border-charcoal'
        break
      case 'green':
        colorStyles = 'border-mostlyGreen'
        break
      case 'primary':
      default:
        colorStyles = 'border-primary'
        break
    }
    return colorStyles
  }, [color])

  const inputColorClasses = useMemo(() => {
    let colorStyles = ''
    switch (color) {
      case 'dark':
        colorStyles =
          'peer-checked:bg-charcoal peer-checked:text-white text-[#B5B6AB] hover:bg-[#d9d9d9]'
        break
      case 'green':
        colorStyles =
          'peer-checked:bg-mostlyGreen peer-checked:text-white text-[#B5B6AB] hover:bg-[#d9d9d9]'
        break
      case 'primary':
      default:
        colorStyles =
          'peer-checked:bg-primary peer-checked:text-white text-charcoal hover:bg-[#FFF3F4]'
        break
    }
    return colorStyles
  }, [color])

  const containerSizeClasses = useMemo(() => {
    let sizeStyles = ''
    switch (size) {
      case 'large':
        sizeStyles = 'h-14 text-lg font-bold p-1'
        break
      case 'small':
      default:
        sizeStyles = 'h-9 text-sm font-medium p-0.5'
        break
    }
    return sizeStyles
  }, [size])

  const inputSizeClasses = useMemo(() => {
    let sizeStyles = ''
    switch (size) {
      case 'large':
        sizeStyles = 'text-lg font-bold px-5 py-[10px]'
        break
      case 'small':
      default:
        sizeStyles = 'text-sm leading-none font-medium px-[15px] py-2'
        break
    }
    return sizeStyles
  }, [size])

  const getCornerClasses = (idx: number, itemCount: number) => {
    switch (true) {
      case idx === 0:
        return 'rounded-l-full'
      case idx === itemCount - 1:
        return 'rounded-r-full'
      default:
        return ''
    }
  }

  if (!options) return null

  return (
    <div
      role='radiogroup'
      aria-labelledby={id}
      className={`flex flex-row overflow-hidden border rounded-full ${containerColorClasses} ${containerSizeClasses} ${className}`}
    >
      {options.map((option, idx, optionsArr) => (
        <div
          key={`${id}_${option.value}`}
          className={`${getCornerClasses(
            idx,
            optionsArr.length
          )} transition-colors duration-200 ease-in-out flex-1 select-none bg-white`}
        >
          <input
            id={`${id}_${option.value}`}
            className='peer sr-only'
            name={id}
            onChange={handleOnChange}
            type='radio'
            value={option.value}
            disabled={disabled}
            checked={option.value === value}
          />
          <label
            htmlFor={`${id}_${option.value}`}
            className={`transition-colors duration-200 ease-in-out rounded-full peer-disabled:opacity-40 peer-disabled:cursor-default h-full flex items-center justify-center cursor-pointer text-center ${inputSizeClasses} ${inputColorClasses}`}
          >
            {option.label}
          </label>
        </div>
      ))}
    </div>
  )
}

export default TabSwitch
