import { IconProps } from './types'

export const PlanterToolkit = ({ className }: IconProps) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      className={className}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.1172 5.23529C16.1172 6.17252 15.8128 7.03865 15.2974 7.74019C16.8594 8.1987 17.9999 9.64263 17.9999 11.353C17.9999 13.4321 16.3144 15.1177 14.2352 15.1177C13.466 15.1177 12.7507 14.887 12.1547 14.491L12.3525 18.8824H7.64663L7.94799 15.0593C7.38431 15.3555 6.74249 15.5231 6.06154 15.5231C3.81841 15.5231 2 13.7047 2 11.4615C2 10.1843 2.5896 9.04469 3.51148 8.30014C3.14896 7.67583 2.94138 6.95039 2.94138 6.17647C2.94138 3.83738 4.83758 1.94118 7.17667 1.94118C7.76926 1.94118 8.33343 2.06288 8.84553 2.28265C9.61502 1.49148 10.6911 1 11.8819 1C14.221 1 16.1172 2.89621 16.1172 5.23529Z'
        fill='currentColor'
      />
    </svg>
  )
}
