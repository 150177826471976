import { useEffect, useMemo } from 'react'
import { MdClose } from 'react-icons/md'

import { useAuth } from 'src/providers/AuthProvider'
import { useFocusActionState } from 'src/providers/FocusActionProvider'
import { useMenuState } from 'src/providers/MenuStateProvider'
import { useUser } from 'src/providers/UserProvider'

import { Image } from 'src/components/atoms/Image'
import { DesktopNavigation } from 'src/components/layouts/Header/DesktopNavigation'
import { MobileNavigation } from 'src/components/layouts/Header/MobileNavigation'
import { MobileNavigationToggle } from 'src/components/layouts/Header/MobileNavigationToggle'

import { env } from 'src/env/client.mjs'
import useMediaQuery from 'src/hooks/useMediaQuery'
import { Stages } from 'src/types/stages'

import { buildAdminLinks, buildUserLinks, buildAdvisorLinks } from './helper'

const FocussedActionHeader = () => {
  const { closeFocussedHeader } = useFocusActionState()
  const handleFocusClose = () => {
    closeFocussedHeader()
  }
  return (
    <nav
      id='main_nav'
      className='px-5 md:px-[30px] relative flex flex-row items-center justify-between w-full max-w-[1220px] place-self-center'
    >
      <div className='relative w-10 h-10 lg:h-[60px] lg:w-[60px]'>
        <Image
          tabIndex={0}
          fill
          alt='Trees That Count logo'
          src='/img/brand-mobile.svg'
          className='object-contain cursor-pointer'
          onClick={handleFocusClose}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              handleFocusClose()
            }
          }}
        />
      </div>
      <MdClose
        tabIndex={0}
        className='cursor-pointer w-7 h-7 sm:w-8 sm:h-8'
        onClick={handleFocusClose}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            handleFocusClose()
          }
        }}
      />
    </nav>
  )
}

export const Header = () => {
  const { isStaff, isAdvisor } = useAuth()
  const { user, isAdminMode, isLoading } = useUser()
  const isMobile = useMediaQuery('(max-width: 1024px)')
  const { isOpen, setIsOpen } = useMenuState()
  const isProduction = env.NEXT_PUBLIC_APP_STAGE === Stages.PRODUCTION
  const { isFocus } = useFocusActionState()

  useEffect(() => {
    if (!isMobile) {
      setIsOpen(false)
    }
  }, [isMobile, setIsOpen])

  const links = useMemo(() => {
    if (isLoading) return []
    if (isStaff && isAdminMode) {
      return buildAdminLinks()
    }
    if (isAdvisor && isAdminMode) {
      return buildAdvisorLinks()
    }

    return buildUserLinks({ user })
  }, [isLoading, isStaff, isAdminMode, isAdvisor, user])

  return (
    <header
      className='z-20 relative py-5 bg-white shadow-[0_15px_15px_0_rgba(0,0,0,.03)]'
      role='banner'
    >
      {isFocus ? (
        <FocussedActionHeader />
      ) : (
        <>
          <nav
            id='main_nav'
            className='relative flex flex-row justify-between px-0 sm:flex-nowrap sm:justify-start md:justify-center'
          >
            <DesktopNavigation links={links} />
            <MobileNavigationToggle />

            {isProduction ? null : (
              <div className='absolute p-2 text-center w-40 -left-10 bg-[rgba(255,255,0,.8)] bg-clip-border -rotate-45 shadow-[0_2px_2px_0_rgba(200,200,0,.5)] capitalize'>
                {env.NEXT_PUBLIC_APP_STAGE}
              </div>
            )}
          </nav>
          {isOpen ? (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
            <div
              onClick={() => {
                setIsOpen(false)
              }}
              className='menu-overlay'
            >
              <MobileNavigation links={links} />
            </div>
          ) : null}
        </>
      )}
    </header>
  )
}
